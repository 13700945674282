/* Mixins */
.facility-batch-add-component .transform-column, .facility-batch-add-component .transform-column-middle {
  height: 45vh !important;
}
.facility-batch-add-component .left-transform-column .ul-list {
  max-height: calc(45vh - 88px) !important;
  height: calc(100% - 88px) !important;
}
.facility-batch-add-component .page-table-search__input {
  margin-top: 8px;
  width: 100%;
  max-width: 100%;
}